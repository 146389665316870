import { Link, Box, styled, useTheme } from '@mui/material';
import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { COLOR_MAP } from 'src/utils/constants/scss-variables.constants';
import { TypeIcon } from 'src/components';

const CellContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  gap: '0.5rem',
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  fontSize: '0.9rem',
  fontWeight: 500,
  '& > p': {
    fontSize: '0.7rem',
    color: COLOR_MAP['gray-darker'],
  },
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const IconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
}));

const ReferralNameCell = ({ row }) => {
  const theme = useTheme();
  return (
    <CellContainer onClick={(e) => e.stopPropagation()}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          gap: '0.5rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <IconContainer>
            <TypeIcon
              type={row.object_type || row.content_type}
              style={{
                color: theme.palette.primary.main,
              }}
            />
          </IconContainer>
          <ContentContainer>
            <Link
              href={`/profiles/${row.content_type || 'hcp'}/${row.provider_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>{row.name || 'n/a'}</span>
              <Icon
                icon={faExternalLinkAlt}
                size="sm"
                style={{ marginLeft: '0.25rem' }}
              />
            </Link>

            <p>
              {row.city}, {row.state}
            </p>
            <p>{row.specialty}</p>
          </ContentContainer>
        </Box>
      </Box>
    </CellContainer>
  );
};

export default ReferralNameCell;
